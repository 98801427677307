import React from "react";
import { Container, CssBaseline, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Home } from "./pages/Home";

const theme = createTheme({
  palette: {
    primary:{
      main: '#2b3d4f'
    },
    secondary: {
      main: '#ff9100'
    },
  },
});
 

function App() {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (window.location.host === 'ajuda.sischef.com'){
      window.location.href = `https://sischef.notion.site`;
    }

    setLoading(false)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <div className="App">

      {loading ? <LoadingElement/> : <Home/>}
      
      </div>
    </ThemeProvider>
  );
}

const LoadingElement = () => {

  return (
    <React.Fragment>
      <CssBaseline/>
      <Container maxWidth="md">
        <Typography>Carregando. Aguarde...</Typography>
      </Container>
    </React.Fragment>  
  )
  
}

export default App;
